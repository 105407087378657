import React from 'react';
import { Formik } from 'formik'
import * as Yup from 'yup'

// Schema for yup
const validationSchema = Yup.object().shape({
    yourName: Yup.string()
      .min(2, "*Names must have at least 2 characters")
      .max(100, "*Names can't be longer than 100 characters")
      .required("*Name is required"),
    email: Yup.string()
      .email("*Must be a valid email address")
      .max(100, "*Email must be less than 100 characters")
      .required("*Email is required"),
    yourMessage: Yup.string()
      .min(2, "*Names must have at least 2 characters")
      .max(100, "*Names can't be longer than 100 characters")
      .required("*A message is required"),
  })

const ContactForm = () => {
    return (
        <>
        <Formik
            initialValues={{
                yourName: "",
                email: "",
                yourMessage: "",
                companyName: "",
                staff: ""
            }}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
                //console.log('Values: ' + JSON.stringify(values));
                fetch(
                    "https://cors-anywhere.herokuapp.com/https://xncold7s8h.execute-api.us-west-2.amazonaws.com/production/contact-work-modal",
                    {
                        headers: { "Content-Type": "application/json; charset=utf-8" },
                        method: "POST",
                        body: JSON.stringify(values),
                    },
                ).then(response => {
                    if (response.status === 200) {
                        actions.setStatus({
                            success: "Message Sent",
                        })
                    } else {
                        actions.setStatus({
                            fail: "Network Error",
                        })
                    }
                })

                setTimeout(() => {
                    //console.log(values)
                    //actions.setSubmitting(false)
                    actions.resetForm()
                }, 900)
            }} 
            >
            {({
                status,
                values,
                touched,
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
            }) => (       
            
        <form onSubmit={handleSubmit}>
            <div className="field half first">
              <label htmlFor="name">
                  Name{" "}
                  { touched.yourName && errors.yourName ? (
                      <div className="error-message"> { errors.yourName }</div>
                  ) : null }
            </label>
            <input
              type="text"
              name="yourName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.yourName}
              className={touched.yourName && errors.yourName ? "error" : null}
            />
            </div>
            <div className="field half">
                <label htmlFor="email">
                    Email{" "}
                  { touched.email && errors.email ? (
                      <div className="error-message"> { errors.email }</div>
                  ) : null }
                </label>
                <input
                    type="text"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    className={touched.email && errors.email ? "error" : null}
                />
            </div>
            <div className="field half first">
              <label htmlFor="name">
                  Company Name{" "}
                  { touched.companyName && errors.companyName ? (
                      <div className="error-message"> { errors.companyName }</div>
                  ) : null }
            </label>
            <input
              type="text"
              name="companyName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.companyName}
              className={touched.companyName && errors.companyName ? "error" : null}
            />
            </div>
            <div className="field half">
                <label htmlFor="staff">
                    Number of Staff{" "}
                  { touched.staff && errors.staff ? (
                      <div className="error-message"> { errors.staff }</div>
                  ) : null }
                </label>
                <input
                    type="text"
                    name="staff"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.staff}
                    className={touched.staff && errors.staff ? "error" : null}
                />
            </div>
            <div className="field">
                <label htmlFor="message">
                  Message{" "}
                  { touched.yourMessage && errors.yourMessage ? (
                      <div className="error-message"> { errors.yourMessage }</div>
                  ) : null }
                </label>
              <textarea rows="4"
              name="yourMessage"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.yourMessage}
              className={
                touched.yourMessage && errors.yourMessage ? "error" : null
              }>
              </textarea>
            </div>
            
            <button disabled={ isSubmitting } type="submit" className="special">
                { status && status.success ? "Message Sent!" : isSubmitting ? "Submitting..." : "Submit"}
            </button>
            {/* <ul className="actions">
              <li>
                <button disabled={isSubmitting} type="submit" className="special">
                    { status && status.success ? "Message Sent!" : isSubmitting ? "Submitting..." : "Submit"}
                </button>
              </li>
              <li>
                <button type="reset">Reset</button>
              </li>
            </ul> */}
          </form>
        )}
        </Formik>
        </>
    )
}

export default ContactForm;